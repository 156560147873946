import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Sidebar } from 'primereact/sidebar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Avatar } from 'primereact/avatar';

//Services
import PageService from '../../service/PageService';
import { Loader } from '../../components/Loader/Loader';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import {
  APP_BASE_URL,
  defaultRowOptionsDashboard,
  SkeletonbodyTemplate,
  Skeletonitems 
} from '../../appconfig/Settings';
import { LoginAnalysisDashboardColumns } from '../../appconfig/DatatableSetting';

const Dashboard = () => {
  document.title = 'Dashboard | Venture Studio';

  const pageService = new PageService();

  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [notifications, setNotifications] = useState<any>([]);
  const [visibleNotification, setVisibleNotification] = useState<boolean>(false);
  const [LoginAnalysisTable, setLoginAnalysisTable] = useState<any>([]);
  const [isHoveredAnalysis, setIsHoveredAnalysis] = useState(false);

  useEffect(() => {
    getDashboardDataFromApi();
  }, []);

  // Get Dashboard Data
  const getDashboardDataFromApi = () => {
    setPageLoad(true);
    // Api call
    pageService
      .getDashboardData()
      .then((response) => {
        // Get response
        if (response) {
          setDashboardData({
            "notification_count": response?.notification_count
          });
          if (!window.cn(response?.login_analysis) && response?.login_analysis.length > 0){
            setLoginAnalysisTable(response?.login_analysis);
          }
          setPageLoad(false);
        } else {
          setDashboardData({});
          setPageLoad(false);
        }
      });
  };

  // Get notifications from api
  const getNotificationsApiCall = () => {
    pageService
      .getNotifications()
      .then((response) => {
        // Get response
        if (response) {
          setNotifications(response);
          setPageLoad(false);
        } else {
          setNotifications([]);
          setPageLoad(false);
        }
      });
  };

  // Notifications show
  const handleNotificationShowClick = () => {
    setVisibleNotification(true);
    getNotificationsApiCall();
  };

  // Notifications hide
  const handleNotificationHideClick = () => {
    setVisibleNotification(false);
    getDashboardDataFromApi();
  }

  // On clear notification
  const clearNotification = (id: any) => {
    // Clear notification api call
    pageService
      .clearNotification(id)
      .then((response) => {
        // Get response
        if (response) {
          getNotificationsApiCall();
        }
      });
  };

  // Format created_at for Posted time show
  const PostedDate = (datetime: any) => {
    const postedTime = formatDistanceToNow(new Date(datetime), { addSuffix: true });
    return postedTime;
  };

  // Open brochure
  const onClickOpenBrochure = () => {
    axios.get(APP_BASE_URL + '/set-brochure-data?client_id=' + localStorage.getItem("id"), { withCredentials: true }).then((response) => {
      // Get response
      if (response) {
        window.open(response?.data?.url, "_blank");
        setPageLoad(false);
      } else {
        setPageLoad(false);
      }
    });
  };

  // For full name body template
  const fullNameBodyTemplate = (rowData: any, rowIndex: any) => {
    return(
      <>
          <Link className="filter-roles"
            to="/investor/details"
            state={{ investor_id: rowData?.id }}
            style={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <Avatar style={{ backgroundColor: '#9c27b0' }} label={rowData?.full_name.charAt(0).toUpperCase()} shape="circle" />&nbsp;&nbsp;
            <p>{rowData?.full_name}</p>
          </Link>
      </>
    );
  };

  return (
    <>
      <div className="layout-dashboard">
        <div className='main-header'>
          <h4 className='header'> Dashboard </h4>
          {
            localStorage.getItem("user_type") == "broker" || localStorage.getItem("user_type") == "admin" ? 
              <div style={{ display: "flex", gap: "10px" }}>
                <span onClick={() => handleNotificationShowClick()} style={{ cursor: 'pointer' }}>
                  <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.5rem' }}>
                    <Badge value={!window.cn(dashboardData) && dashboardData?.notification_count != undefined ? dashboardData?.notification_count : 0}></Badge>
                  </i>
                </span>
              </div>
            :
              <></>
          }
        </div>

        {
          localStorage.getItem('user_type') == "admin" ? 
            <div className='grid dashboard-card'>
              <div className="col-12 md:col-3">
                <div className="card relative h-6rem border-round-xl p-3 box fadein animation-duration-500">
                  <span className="text-m font-medium line-height-1"><b>Investors</b></span>
                  <div className="flex justify-content-between">
                    <div className="flex justify-content-between align-items-center">
                      <div className="line-height-2 text-4xl mt-2">10</div>
                    </div>
                  </div>
                  <i className="absolute pi pi-dollar" style={{ bottom: "20px", right: "25px", fontSize: "3rem" }}></i>
                </div>
              </div>

              <div className="col-12 md:col-3">
                <div className="card relative h-6rem border-round-xl p-3 box fadein animation-duration-500">
                  <span className="text-m font-medium line-height-1"><b>Brokers</b></span>
                  <div className="flex justify-content-between">
                    <div className="flex justify-content-between align-items-center">
                      <div className="line-height-2 text-4xl mt-2">7</div>
                    </div>
                  </div>
                  <i className="absolute pi pi-users" style={{ bottom: "20px", right: "25px", fontSize: "3rem" }}></i>
                </div>
              </div>

              <div className="col-12 md:col-3">
                <div className="card relative h-6rem border-round-xl p-3 box fadein animation-duration-500">
                  <span className="text-m font-medium line-height-1"><b>Total Jobs</b></span>
                  <div className="flex justify-content-between">
                    <div className="flex justify-content-between align-items-center">
                      <div className="line-height-2 text-4xl mt-2">12</div>
                    </div>
                  </div>
                  <i className="absolute pi pi-briefcase" style={{ bottom: "20px", right: "25px", fontSize: "3rem" }}></i>
                </div>
              </div>

              <div className="col-12 md:col-3">
                <div className="card relative h-6rem border-round-xl p-3 box fadein animation-duration-500">
                  <span className="text-m font-medium line-height-1"><b>Total Applicants</b></span>
                  <div className="flex justify-content-between">
                    <div className="flex justify-content-between align-items-center">
                      <div className="line-height-2 text-4xl mt-2">0</div>
                    </div>
                  </div>
                  <i className="absolute pi pi-user-plus" style={{ bottom: "20px", right: "25px", fontSize: "3rem" }}></i>
                </div>
              </div>

              <div className="col-12 md:col-6">
                <div className='card'>
                  <h5 className='header'> Investor Login Analysis </h5>
                  {pageLoad == false ? (
                    <>
                      <DataTable
                        className="datatable-responsive"
                        value={LoginAnalysisTable}
                        paginator={LoginAnalysisTable.length > 0 && true}
                        rows={defaultRowOptionsDashboard}
                        emptyMessage="No Data Found"
                        size='normal'
                      >
                        {LoginAnalysisDashboardColumns.map((col, i) => {
                          if (col.field === 'sr_no') {
                            return (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={(_, { rowIndex }) => rowIndex + 1}
                              />
                            );
                          } else if (col.field === 'full_name') {
                            return (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={fullNameBodyTemplate}
                                sortable
                              />
                            );
                          } else {
                            return (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                sortable
                              />
                            );
                          }
                        })}
                      </DataTable>
                    </>
                  ) : (
                    <>
                      {/* Skeleton Data table */}
                      <DataTable value={Skeletonitems}>
                          {LoginAnalysisDashboardColumns.map((col, i) => (
                          <Column
                            key={col.field}
                            field={col.field}
                            header={col.header}
                            body={SkeletonbodyTemplate}
                          />
                        ))}
                      </DataTable>
                    </>
                  )}

                </div>
              </div>
            </div>
          :
          <section
          id="hero-animated"
          className="hero-animated flex align-items-center "
          style={{ justifyContent: "center" }}
        >
          <div
            className="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
            data-aos="zoom-out"
          >
            <img
              src="/assets/images/logo.png"
              className="img-fluid animated"
            />
            <p className="mb-0">
              HELLO {localStorage.getItem('full_name')}
            </p>
            <h2>
              Welcome
            </h2>
            {/* {localStorage.getItem('user_type') == "client"
              ?
              <Button
                type="button"
                label='Open Brochure'
                className="p-button-success"
                onClick={() => onClickOpenBrochure()}
              />
              :

              ""
            } */}
          </div>
        </section>
        }
      </div>

      {/* Notification Sidebar */}
      <Sidebar className='p-slider-header-custom' style={{ width: "500px" }} visible={visibleNotification} onHide={() => handleNotificationHideClick()} position='right'>
        <h4>Notifications</h4>

        {
          !window.cn(notifications) && notifications.length > 0 ?
            <ul className="list-none m-0 p-0">
              {
                notifications.map((item: any, index: any) => {
                  return (
                    <>
                        <li>
                          <a className="flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                          <Avatar style={item?.user?.user_type == "broker" ? { backgroundColor: '#2196F3' } : { backgroundColor: '#9c27b0' } } label={item?.user?.full_name.charAt(0).toUpperCase()} size="xlarge" shape="circle" />
                            <div className="ml-3">
                              <span className="mb-2 font-semibold" style={{ color: "black" }}>{item?.user?.full_name}</span>
                              <p className="text-color-secondary m-0">{item?.notification_message}</p>
                            <p className="text-color-secondary m-0">{PostedDate(item?.created_at)}</p>
                            </div>
                            <Button
                              className="ml-auto cursor-pointer p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-secondary"
                              icon="pi pi-times"
                              aria-label="Cancel"
                              onClick={() => clearNotification(item?.id)}
                            />
                          </a>
                        </li>
                    </>
                  )
                })
              }
            </ul>
            :
            <></>
        }
      </Sidebar>

      {/* Loader Start */}
      {
        pageLoad && <Loader />
      }
      {/* Loader End */}
    </>
  );
};

export default Dashboard;
