import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import moment from "moment/moment";

import { SkeletonbodyTemplate, Skeletonitems } from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';
import { Loader } from '../../../components/Loader/Loader';
import { LoginAnalysisColumns } from '../../../appconfig/DatatableSetting';

export const BrokersDetails = () => {
    document.title = "Broker Details | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const pageService = new PageService();
    const toast = useRef<any>(null);
    const hasFetchedData = useRef(false);

    const [pageLoad, setPageLoad] = useState(false);
    const [brokerId, setBrokerId] = useState<any>();
    const [brokerData, setBrokerData] = useState<any>({});
    const [notificationsList, setNotificationsList] = useState<any>([]);
    const [notificationLoad, setNotificationLoad] = useState<boolean>(false);

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        if (location.state) {
            const state = location.state;
            setBrokerId(state);
            getBrokerDetailsFromAPI(state);
            getUserAllNotificationsFromAPI(state);
        }
    }, []);

    // Get Broker Details
    const getBrokerDetailsFromAPI = async (state: any) => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleClientDetails(state.broker_id)
            .then((response) => {
                // Get response
                if (response) {
                    const responseData = response;
                    setBrokerData(responseData);
                    setPageLoad(false);
                } else {
                    setPageLoad(false);
                    setBrokerData({});
                }
            });
    };

    // Get Broker analysis
    const getUserAllNotificationsFromAPI = async (state: any) => {
        setNotificationLoad(false);
        // Api call
        pageService
            .getUserAllNotifications(state.broker_id)
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    setNotificationsList(DataList?.login_notification);
                    setNotificationLoad(true);
                } else {
                    setNotificationLoad(false);
                    setNotificationsList([]);
                }
            });
    };

    // Date formate template
    const dateFormatCreatedAtTemplate = (rowData: any, rowIndex: any) => {

        return (
            <>
                {rowData.created_at === rowData[rowIndex.field] && rowData.created_at !== null ? moment.utc(rowData.created_at).format('MMM DD, YYYY hh:mm A') : '-'}
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <h3>
                <Button
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate(-1)}
                />
                Broker Details</h3><br />
            <div>
                <div style={{ display: "inline-block" }}>
                    <h5 style={{ marginLeft: "16px" }}> <b>Status</b> : {!window.cn(brokerData) && brokerData?.status == 0 ? <><Badge value="Pending" severity="warning"></Badge></> : brokerData?.status == 1 ? <><Badge value="Active" severity="success"></Badge></> : <><Badge value="Access Revoked" severity="danger"></Badge></>}</h5>
                </div>
            </div>

            <div className='grid'>
                <div className="col-12 md:col-12">
                    <div className='card'>
                        <div className="field col">
                            <div className="grid">
                                <div className="field col-4 flex flex-column">
                                    <p> <b>Full Name</b> : {!window.cn(brokerData) ? brokerData?.first_name + " " + brokerData?.last_name : ""}</p>
                                </div>

                                <div className="field col-4 flex flex-column">
                                    <p> <b>Email</b> : {!window.cn(brokerData) ? brokerData?.email : ""}</p>
                                </div>

                                <div className="field col-4 flex flex-column">
                                    <p> <b>Mobile No.</b> : {!window.cn(brokerData) ? "+" + brokerData?.country?.phonecode + " " + brokerData?.mobile : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-3">
                <div className='card'>
                    <h4 className='header'> Login Analysis </h4>
                    {notificationLoad ? (
                        <>
                            <DataTable
                                className="datatable-responsive"
                                value={notificationsList}
                                paginator={notificationsList.length > 0 && true}
                                rows={8}
                                emptyMessage={"No Data Found"}
                            >
                                {LoginAnalysisColumns.map((col, i) => {
                                    if (col.field === 'created_at') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={dateFormatCreatedAtTemplate}
                                                sortable
                                            />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                sortable
                                                filter
                                            />
                                        );
                                    }
                                })}
                            </DataTable>
                        </>
                    ) : (
                        <>
                            {/* Skeleton Data table */}
                            <DataTable value={Skeletonitems}>
                                {LoginAnalysisColumns.map((col, i) => (
                                    <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        body={SkeletonbodyTemplate}
                                    />
                                ))}
                            </DataTable>
                        </>
                    )}
                </div>
            </div>

            {/* Loader Start */}
            {
                pageLoad && <Loader />
            }
            {/* Loader End */}
        </>
    )
}