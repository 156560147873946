import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';

//Buffer Storage
import { Buffer } from 'buffer';

//Custom Validation
import { LoginValidate } from '../../config/Validate';

//API Constant Settings
import { LOGIN } from '../../config/ApiConstant';
import axiosInstance from '../../config/axiosInstance';


export const Login = () => {
  //Document Title
  document.title = "Login | Venture Studio"

  //Navifate History
  const navigate = useNavigate();

  //Page Data
  const [loginData, setLoginData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  //Loading/Toast Components
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const toast = useRef<any>(null);

  const onSubmit = async () => {
    try {
      const { errors, isError } = LoginValidate(loginData);
      setErrors(errors);

      if (!isError) {
        setButtonLoading(true);

        //Login Data Value Trim
        const email = loginData.email.trim();

        //Password base64 convert
        let passwordBuff = Buffer.from(loginData.password).toString('base64');

        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', passwordBuff);

        //API Call
        const response = await axiosInstance.post(LOGIN, formData);
        console.log();
        //Get API Reponse
        if (response.status == 200) {
          // get Data for localstorage
          const { token, user } = response.data.data;
          const usertoken = token;
          const full_name = user.full_name;
          const user_type = user.user_type;
          const profile_img = user.profile_img;

          //After Success Login Set Token in Common
          axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usertoken;

          //set localstorage
          localStorage.setItem('token', usertoken);
          localStorage.setItem('id', user?.id);
          localStorage.setItem('full_name', full_name);
          localStorage.setItem('user_type', user_type);
          localStorage.setItem('profile_img', profile_img);

          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Login Successful',
          });

          setButtonLoading(false);
          navigate('/dashboard');
        } else if (response.status == 400){
          console.log(response.data);
          toast.current?.show({
            severity: 'error',
            summary: 'Message',
            detail: 'Email ID or Password is incorrect. ',
          });
          setButtonLoading(false);
        }
      }
    } catch (error: any) {
      setButtonLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error.response.data.error,
      });
    }
  };

  const _onHandleChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setLoginData({ ...loginData, [name]: val });
  };

  return (
    <div className="login-body">
      <div className="login-panel p-fluid">
        <Toast ref={toast} />
        <div className="login-wrapper">
          <div className="logo-container">
            <img
              src="/assets/images/logo.png"
              className="login-logo"
              alt="login-logo"
            />
          </div>

          <div>
            <div className="field">
              <InputText
                value={loginData.email}
                name="email"
                autoComplete="off"
                placeholder="Email"
                onChange={(e) => _onHandleChange(e, 'email')}
                className={errors['email'] && 'p-invalid'}
              />
              <small className="p-invalid-txt d-flex">{errors['email']}</small>
            </div>
            <div className="field">
              <Password
                value={loginData.password}
                name="password"
                placeholder="Password"
                onChange={(e) => _onHandleChange(e, 'password')}
                feedback={false}
                toggleMask
                className={errors['password'] && 'p-invalid'}
              />
              <small className="p-invalid-txt d-flex">
                {errors['password']}
              </small>
            </div>

            <div className="button-container">
              <Button
                type="button"
                label="Login"
                loading={buttonLoading}
                onClick={onSubmit}
                className="p-button-info"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
