import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';


//Services
import PageService from '../../../service/PageService';
import { Loader } from '../../../components/Loader/Loader';

export const DataScrapperDetails = () => {
    document.title = "Data Scrapper Details | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const pageService = new PageService();
    const toast = useRef<any>(null);
    const hasFetchedData = useRef(false);

    const [pageLoad, setPageLoad] = useState(false);
    const [dataScrapperId, setDataScrapperId] = useState<any>();
    const [dataScrapperData, setDataScrapperData] = useState<any>({});

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        if (location.state) {
            const state = location.state;
            setDataScrapperId(state);
            getDataScrapperDetailsFromAPI(state);
        }
    }, []);

    // Get Data Scrapper Details
    const getDataScrapperDetailsFromAPI = async (state: any) => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleClientDetails(state.datascrapper_id)
            .then((response) => {
                // Get response
                if (response) {
                    const responseData = response;
                    setDataScrapperData(responseData);
                    setPageLoad(false);
                } else {
                    setPageLoad(false);
                    setDataScrapperData({});
                }
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <h3>
                <Button
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate(-1)}
                />
                Data Scrapper Details</h3><br />
            <div>
                <div style={{ display: "inline-block" }}>
                    <h5 style={{ marginLeft: "16px" }}> <b>Status</b> : {!window.cn(dataScrapperData) && dataScrapperData?.status == 0 ? <><Badge value="Pending" severity="warning"></Badge></> : dataScrapperData?.status == 1 ? <><Badge value="Active" severity="success"></Badge></> : <><Badge value="Access Revoked" severity="danger"></Badge></>}</h5>
                </div>
            </div>

            <div className='card'>
                <div className="field col">
                    <div className="grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Full Name</b> : {!window.cn(dataScrapperData) ? dataScrapperData?.first_name + " " + dataScrapperData?.last_name : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Email</b> : {!window.cn(dataScrapperData) ? dataScrapperData?.email : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Mobile No.</b> : {!window.cn(dataScrapperData) ? "+" + dataScrapperData?.country?.phonecode + " " + dataScrapperData?.mobile : ""}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Loader Start */}
            {
                pageLoad && <Loader />
            }
            {/* Loader End */}
        </>
    )
}