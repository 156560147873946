import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppTopbar from './layouts/AppTopbar';
import AppMenu from './layouts/AppMenu';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import './flags.css';

// Pages
import Dashboard from './pages/dashboard/Dashboard';
import { ChangePassword } from './pages/ChangePassword';
import { ClientsList } from './pages/Admin/Clients/ClientsList';
import { ClientsDetails } from './pages/Admin/Clients/ClientsDetails';
import { CareersList } from './pages/Admin/Careers/CareersList';
import { AddUpdateCareers } from './pages/Admin/Careers/AddUpdateCareers';
import { JobRoles } from './pages/Admin/Master/JobRoles';
import { CareerJobDetailsView } from './pages/Admin/Careers/CareerJobDetailsView';
import { CareerApplicants } from './pages/Admin/Careers/CareerApplicants';
import { CareerApplicantsDetail } from './pages/Admin/Careers/CareerApplicantsDetail';
import { InvestmentMaterial } from './pages/Admin/Master/InvestmentMaterial';
import { InvestorMaterialList } from './pages/Admin/Investors/InvestorMaterialList';
import { InvestmentMaterialApproval } from './pages/Admin/Master/InvestmentMaterialApproval';
import { UserAllNotificationsList } from './pages/dashboard/UserAllNotificationsList';
import { InvestorsList } from './pages/Admin/Investors/InvestorsList';
import { InvestorsDetails } from './pages/Admin/Investors/InvestorsDetails';
import { BrokersList } from './pages/Admin/Brokers/BrokersList';
import { BrokersDetails } from './pages/Admin/Brokers/BrokersDetails';
import { PerticularBrokerInvestorList } from './pages/Admin/Brokers/PerticularBrokerInvestorList';
import { TrashList } from './pages/Admin/Master/TrashList';
import { UserSettings } from './pages/dashboard/UserSettings';
import { TeamLeaderList } from './pages/Admin/Team Leaders/TeamLeaderList';
import { TeamLeaderDetails } from './pages/Admin/Team Leaders/TeamLeaderDetails';
import { LeadGeneratorsList } from './pages/Admin/Lead Generators/LeadGeneratorsList';
import { LeadGeneratorsDetails } from './pages/Admin/Lead Generators/LeadGeneratorsDetails';
import { TestExcel } from './pages/TestExcel';
import { DataScrappersList } from './pages/Admin/Data Scrappers/DataScrappersList';
import { DataScrapperDetails } from './pages/Admin/Data Scrappers/DataScrapperDetails';
import { AddLeads } from './pages/Admin/Leads/AddLeads';
import { LeadsList } from './pages/Admin/Leads/LeadsList';
import { Team } from './pages/Admin/Team Leaders/Team';

const App = (props: any) => {
  const [menuMode, setMenuMode] = useState('sidebar');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [pinActive, setPinActive] = useState(false);
  const [activeInlineProfile, setActiveInlineProfile] = useState(false);
  const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
  const copyTooltipRef = useRef<any>();
  const location = useLocation();

  // Admin Menus
  let menu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-home',
      to: '/dashboard'
    },
    {
      label: 'Clients',
      icon: 'pi pi-users',
      to: '/clients'
    },
    {
      label: 'HR',
      icon: 'pi pi-briefcase',
      items: [
        { label: 'Careers', icon: 'pi pi-minus', to: '/careers' },
        { label: 'Applicants', icon: 'pi pi-minus', to: '/applicants' },
        { label: 'Job Roles', icon: 'pi pi-minus', to: '/master/job/roles' },
      ],
    },
    {
      label: 'Capital Expansion',
      icon: 'pi pi-dollar',
      items: [
        { label: 'Team Leaders', icon: 'pi pi-minus', to: '/team-leaders' },
        { label: 'Brokers', icon: 'pi pi-minus', to: '/brokers' },
        { label: 'Lead Generators', icon: 'pi pi-minus', to: '/lead-generators' },
        { label: 'Data Scrappers', icon: 'pi pi-minus', to: '/data-scrappers' },
        { label: 'Investors', icon: 'pi pi-minus', to: '/investors' },
        { label: 'Investment Material', icon: 'pi pi-minus', to: '/investment-material' },
        { label: 'Invt. Material Approvals', icon: 'pi pi-minus', to: '/invt-material-approvals' },
      ],
    },
    {
      label: 'Trash',
      icon: 'pi pi-trash',
      to: '/trash'
    },
  ];

  // Menu for clients
  if(localStorage.getItem('user_type') == "client"){
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Careers',
        icon: 'pi pi-briefcase',
        items: [
          { label: 'Careers', icon: 'pi pi-minus', to: '/careers' },
          { label: 'Applicants', icon: 'pi pi-minus', to: '/applicants' },
        ],
      }
    ];
  };

  // Menu for investors
  if (localStorage.getItem('user_type') == "investor") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Investment Material',
        icon: 'pi pi-dollar',
        to: '/investor-material'
      },
    ];
  };

  // Menu for team leader
  if (localStorage.getItem('user_type') == "teamleader") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Brokers',
        icon: 'pi pi-users',
        to: '/brokers'
      },
      {
        label: 'Lead Generators',
        icon: 'pi pi-user-plus',
        to: '/lead-generators'
      },
      {
        label: 'Data Scrappers',
        icon: 'pi pi-database',
        to: '/data-scrappers'
      },
      // {
      //   label: 'Team',
      //   icon: 'pi pi-sitemap',
      //   to: '/team'
      // },
    ];
  };

  // Menu for brokers
  if (localStorage.getItem('user_type') == "broker") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Investors',
        icon: 'pi pi-users',
        to: '/investors'
      },
      { 
        label: 'Lead Generators', 
        icon: 'pi pi-user-plus', 
        to: '/lead-generators' 
      },
    ];
  };

  // Menu for lead generator
  if (localStorage.getItem('user_type') == "leadgen") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Data Scrappers',
        icon: 'pi pi-users',
        to: '/data-scrappers'
      },
    ];
  };

  // Menu for datascrapper
  if (localStorage.getItem('user_type') == "datascrapper") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Leads',
        icon: 'pi pi-user-plus',
        to: '/leads'
      },
    ];
  };

  // Menu for marketing
  if (localStorage.getItem('user_type') == "marketing") {
    menu = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        to: '/dashboard'
      },
      {
        label: 'Capital Expansion',
        icon: 'pi pi-dollar',
        items: [
          { label: 'Investment Material', icon: 'pi pi-minus', to: '/investment-material' },
        ],
      },
      {
        label: 'Trash',
        icon: 'pi pi-trash',
        to: '/trash'
      },
      {
        label: 'HR',
        icon: 'pi pi-briefcase',
        items: [
          { label: 'Applicants', icon: 'pi pi-minus', to: '/applicants' },
        ],
      },
    ];
  };

  let menuClick: any;

  let topbarItemClick: any;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
        setResetActiveIndex(true);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
      }

      hideOverlayMenu();
      unblockBodyScroll();
    }

    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;

    if (isOverlay()) {
      setOverlayMenuActive((prevState) => !prevState);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevState) => !prevState);
    } else {
      setStaticMenuMobileActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onToggleMenu = (event: any) => {
    menuClick = true;

    if (overlayMenuActive) {
      setOverlayMenuActive(false);
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarMouseOver = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setSidebarActive(isDesktop());
      setTimeout(() => {
        setPinActive(isDesktop());
      }, 200);
    }
  };

  const onSidebarMouseLeave = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false);
        setPinActive(false);
      }, 250);
    }
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onChangeActiveInlineMenu = (event: any) => {
    setActiveInlineProfile((prevState) => !prevState);
    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
      setResetActiveIndex(true);
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  };

  const layoutClassName = classNames('layout-wrapper', {
    'layout-static': menuMode === 'static',
    'layout-overlay': menuMode === 'overlay',
    'layout-overlay-active': overlayMenuActive,
    'layout-slim': menuMode === 'slim',
    'layout-horizontal': menuMode === 'horizontal',
    'layout-active': menuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-sidebar': menuMode === 'sidebar',
    'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
    'layout-static-inactive':
      staticMenuDesktopInactive && menuMode === 'static',
  });

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <div className="layout-main">
        <AppTopbar
          items={menu}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          topbarMenuActive={topbarMenuActive}
          activeInlineProfile={activeInlineProfile}
          onTopbarItemClick={onTopbarItemClick}
          onMenuButtonClick={onMenuButtonClick}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          onMenuClick={onMenuClick}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
          resetActiveIndex={resetActiveIndex}
        />

        <AppMenu
          changeColorScheme={props.onColorSchemeChange}
          model={menu}
          onRootMenuItemClick={onRootMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          onToggleMenu={onToggleMenu}
          onMenuClick={onMenuClick}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          sidebarActive={sidebarActive}
          sidebarStatic={sidebarStatic}
          pinActive={pinActive}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onSidebarMouseOver={onSidebarMouseOver}
          activeInlineProfile={activeInlineProfile}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          resetActiveIndex={resetActiveIndex}
        />
        <br />

        <div className="layout-main-content">
          <Routes>
            {/* <Route path="/forminputs" element={<FormsInput />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/notifications" element={<UserAllNotificationsList />} />
            <Route path="/change-password" element={<ChangePassword />} />

            {/* Clients */}
            <Route path="/clients" element={<ClientsList />} />
            <Route path="/client/details" element={<ClientsDetails />} />

            {/* Team Leaders */}
            <Route path="/team-leaders" element={<TeamLeaderList />} />
            <Route path="/teamleader/details" element={<TeamLeaderDetails />} />
            <Route path="/team" element={<Team />} />

            {/* Brokers */}
            <Route path="/brokers" element={<BrokersList />} />
            <Route path="/broker/details" element={<BrokersDetails />} />

            {/* Lead Generators */}
            <Route path="/lead-generators" element={<LeadGeneratorsList />} />
            <Route path="/leadgenerators/details" element={<LeadGeneratorsDetails />} />

            {/* Data Scrappers */}
            <Route path="/data-scrappers" element={<DataScrappersList />} />
            <Route path="/datascrapper/details" element={<DataScrapperDetails />} />

            {/* Investors */}
            <Route path="/individual-investors" element={<PerticularBrokerInvestorList />} />
            <Route path="/investors" element={<InvestorsList />} />
            <Route path="/investor/details" element={<InvestorsDetails />} />

            {/* Careers */}
            <Route path="/careers" element={<CareersList />} />
            <Route path="/career/publish" element={<AddUpdateCareers />} />
            <Route path="/career/details" element={<CareerJobDetailsView />} />

            {/* Applicants */}
            <Route path="/applicants" element={<CareerApplicants />} />
            <Route path="/applicant/details/:id" element={<CareerApplicantsDetail />} />

            {/* Master */}
            <Route path="/master/job/roles" element={<JobRoles />} />
            <Route path="/investment-material" element={<InvestmentMaterial />} />

            {/* Investment Material */}
            <Route path="/investor-material" element={<InvestorMaterialList />} />

            {/* Investment Material Approval */}
            <Route path="/invt-material-approvals" element={<InvestmentMaterialApproval />} />

            {/* Trash */}
            <Route path="/trash" element={<TrashList />} />

            {/* Settings */}
            <Route path="/user-settings" element={<UserSettings />} />

            {/* Leads */}
            <Route path="/leads" element={<LeadsList />} />
            <Route path="/add-update-leads" element={<AddLeads />} />

            <Route path="/test-excel" element={<TestExcel />} />
          </Routes>
        </div>

        {/* <AppFooter colorScheme={props.colorScheme} /> */}
      </div>
    </div>
  );
};

export default App;
