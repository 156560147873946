import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// Column
import { LeadsListColumns } from '../../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';

export const LeadsList = () => {
    document.title = "Data Scrappers | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // Date Object
    let today = new Date();
    const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);
    const hasFetchedData = useRef(false);

    // Page service
    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [leads, setLeads] = useState<any>({});
    const [selectedLeadGen, setSelectedLeadGen] = useState<any>({ code: "All", name: "All" });
    const [selectedBroker, setSelectedBroker] = useState<any>({ code: "All", name: "All" });
    const [leadGen, setLeadGen] = useState<any>([]);
    const [broker, setBroker] = useState<any>([]);
    const [userId, setUserId] = useState<any>({});

    // use effect method
    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        if (location.state) {
            const state = location.state;
            setUserId(state);
            getLeadsFromAPI(state);
            getLeadsListFilterFromAPI(state);
        }else{
            getLeadsFromAPI(null);
            getLeadsListFilterFromAPI(null);
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            getLeadsFromAPI(state);
        }else{
            getLeadsFromAPI(null);
        }
    }, [selectedLeadGen, selectedBroker]);

    // Get leads list filters form api
    const getLeadsListFilterFromAPI = (state: any) => {
        // Api call
        pageService
            .getLeadsListFilter(state?.datascrapper_id)
            .then((response) => {
                // Get response
                if (response) {
                    setLeadGen(response?.leadgen);
                    setBroker(response?.brokers);
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                }
            });
    };

    // Get leads from api
    const getLeadsFromAPI = (state: any) => {
        let leadGenCode: any = selectedLeadGen?.code;
        let brokerCode: any = selectedBroker?.code;
        let dataScrapperCode: any = null;

        if (state?.leadgen_id !== undefined && state?.leadgen_id !== "" && state?.leadgen_id){
            leadGenCode = state?.leadgen_id;
        }

        if (state?.broker_id && state?.broker_id !== "" && state?.broker_id) {
            brokerCode = state?.broker_id;
        }

        if (state?.datascrapper_id && state?.datascrapper_id !== "" && state?.datascrapper_id) {
            dataScrapperCode = state?.datascrapper_id;
        }

        // Api call
        pageService
            .getLeads(leadGenCode, brokerCode, dataScrapperCode)
            .then((response) => {   
                // Get response 
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setLeads([]);
                    } else {
                        setLeads(DataList);
                    }
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                    setLeads([]);
                }
            });
    };

    // left part of toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // right part of toolbar
    const rightToolbarTemplate = () => {
        return (
            <>
            {
                localStorage.getItem("user_type") == "datascrapper" || localStorage.getItem("user_type") == "admin" || localStorage.getItem("user_type") == "teamleader" ? 
                <>
                    <Dropdown
                        value={selectedLeadGen}
                        name="name"
                        options={leadGen}
                        filter
                        optionLabel="name"
                        onChange={(e) => setSelectedLeadGen(e.value)}
                    />
                    <Dropdown
                        value={selectedBroker}
                        name="name"
                        options={broker}
                        filter
                        optionLabel="name"
                        placeholder="Select Broker"
                        onChange={(e) => setSelectedBroker(e.value)}
                    />
                </>
                : localStorage.getItem("user_type") == "leadgen" ?
                <>
                    <Dropdown
                        value={selectedBroker}
                        name="name"
                        options={broker}
                        filter
                        optionLabel="name"
                        placeholder="Select Broker"
                        onChange={(e) => setSelectedBroker(e.value)}
                    />
                </>
                :
                <></>
            }
                {/* <div style={{ marginLeft: '15px' }}></div>
                <Calendar
                    value={dates}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => onDateChange(e)}
                    selectionMode="range"
                    showIcon
                /> */}
            </>
        );
    };

    const actionBodyTemplate = (rowData: any, rowIndex: any) => {
        return(
            <>
                <Button
                    icon="pi pi-eye"
                    className="p-button-square p-button-secondary mr-2"
                    onClick={() => {}}
                />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-square p-button-secondary mr-2"
                    onClick={() => {}}
                />
            </>
        );
    };

    const fullNameTemplate = (rowData: any, rowIndex: any) => {
        return (
            <>{rowData?.First_Name} {rowData?.Last_Name}</>
        );
    };

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className='main-header'>
                            <h3>{localStorage.getItem("user_type") !== "datascrapper" ? 
                                <Button
                                    icon="pi pi-arrow-left"
                                    className="p-button-secondary mr-2"
                                    onClick={() => navigate(-1)}
                                /> : <></>} Leads</h3>
                            {
                                localStorage.getItem("user_type") == "datascrapper" ? 
                                    <Button
                                        label="New Lead"
                                        icon="pi pi-plus"
                                        className="p-button-outlined mr-2"
                                        onClick={() => navigate('/add-update-leads')}
                                    />
                                :
                                    <></>
                            }
                        </div>
                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        ></Toolbar>

                        {/* Datatable Start */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    className="datatable-responsive"
                                    value={leads}
                                    paginator={leads.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Leads Found"
                                >
                                    {LeadsListColumns.map((col, i) => {
                                        if (col.field === 'full_name') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={fullNameTemplate}
                                                    filter
                                                    sortable
                                                />
                                            );
                                        } else if (col.field === 'action') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={actionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'sr_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={(_, { rowIndex }) => rowIndex + 1}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable
                                                    filter
                                                />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {LeadsListColumns.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                        {/* Datatable End */}
                    </div>
                </div>
            </div>
        </>
    );
};