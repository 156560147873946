import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

//Services
import PageService from '../../../service/PageService';

import {
    applicantStatusChange,
} from '../../../appconfig/Settings';

import { Loader } from '../../../components/Loader/Loader';
import { Dropdown } from 'primereact/dropdown';

export const CareerApplicantsDetail = () => {
    document.title = "Applicant Detail | Venture Studio"

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef<any>(null);
    const { id } = useParams<any>();

    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [applicantId, setApplicantId] = useState<any>({});
    const [applicantData, setApplicantData] = useState<any>({});
    const [statusChangePageLoad, setStatusChangePageLoad] = useState(false);

    useEffect(() => {
        setApplicantId(id);
        getSingleApplicantDataFromAPI(id);
    }, []);

    // Get single applicant data
    const getSingleApplicantDataFromAPI = (state: any) => {
        setPageLoad(true);
        // Api call
        pageService
            .getSingleApplicantDetails(state)
            .then((response) => {
                // Get response
                if (response) {
                    setApplicantData(response);
                    setPageLoad(false);
                } else {
                    setApplicantData({});
                    setPageLoad(false);
                }
            });
    };

    // on applicant status change
    const onApplicantStatusChange = (e: any, applicantId: any) => {
        try {
            setStatusChangePageLoad(true);
            // request data
            let formData = new FormData();
            formData.append('id', applicantId);
            formData.append('status', e.value.code);

            // call api
            pageService.applicantStatusChange(formData).then((response) => {
                // Get response
                if (response) {
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                    setTimeout(() => {
                        setStatusChangePageLoad(false);
                        getSingleApplicantDataFromAPI(id);
                    }, 1000);
                } else {
                    setStatusChangePageLoad(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            });
        } catch (error: any) {
            setStatusChangePageLoad(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className='main-header'>
                <h3> {/*<Button
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate(-1)}
                /> */}   Applicant Details</h3><br /> 
                <div className='mb-3'>
                    <Dropdown
                        value={{ name: applicantData?.status, code: applicantData?.status }}
                        onChange={(e) => onApplicantStatusChange(e, id)}
                        options={applicantStatusChange}
                        optionLabel="name"
                        className='mr-3'
                    ></Dropdown>

                    <Button
                        label='Resume'
                        icon="pi pi-file-pdf"
                        className="p-button-danger mr-2"
                        onClick={() => window.open(applicantData?.resume, "_blank")}
                    />
                    {
                        !window.cn(applicantData) && applicantData?.job_post?.give_task == "Yes" ?
                            <>
                                <Button
                                    label='Task File'
                                    icon="pi pi-file"
                                    className="p-button-info mr-2"
                                    onClick={() => window.open(applicantData?.task_file, "_blank")}
                                    disabled={applicantData?.task_file !== null && applicantData?.task_file !== "null" && applicantData?.task_file !== "" ? false : true}
                                />
                            </>
                        :
                            <></>
                    }
                </div>
                
            </div>

            <div className='card'>
                <div className="field col">
                    <div className="grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Name</b> : {!window.cn(applicantData) ? applicantData?.first_name + " " + applicantData?.last_name : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Email</b> : {!window.cn(applicantData) ? applicantData?.email : ""}</p>
                        </div>

                        <div className="field col-4 flex flex-column">
                            <p> <b>Mobile No.</b> : {!window.cn(applicantData) ? applicantData?.mobile : ""}</p>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        
                    </div>
                </div>
            </div>

            <div className='card'>
                <div className="field col">
                    <h4><b>Applied Job Details</b></h4><br/>
                    <div className="grid">
                        <div className="field col-3 flex flex-column">
                            <p> <b>Job Title</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_title : ""}</p>
                        </div>

                        {/* <div className="field col-3 flex flex-column">
                            <p> <b>Company</b> : {!window.cn(applicantData) ? applicantData?.company?.company_name : ""}</p>
                        </div> */}

                        <div className="field col-3 flex flex-column">
                            <p> <b>Role</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_role?.name : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Location</b> : {!window.cn(applicantData) ? applicantData?.job_post?.job_location : ""}</p>
                        </div>

                        {/* {!window.cn(applicantData) && !window.cn(applicantData?.job_post?.salary_from) ?
                            <div className="field col-3 flex flex-column">
                                <p> <b>Salary</b> : {!window.cn(applicantData) ? applicantData?.job_post?.currency + " " + applicantData?.job_post?.salary_from + " - " + applicantData?.job_post?.salary_to : ""}</p>
                            </div>
                            :
                            <></>
                        } */}

                        {/* <div className="field col-3 flex flex-column">
                            <p> <b>Experience</b> : {!window.cn(applicantData) ? applicantData?.job_post?.experience_from + " - " + applicantData?.job_post?.experience_to + " Years" : ""}</p>
                        </div>

                        <div className="field col-3 flex flex-column">
                            <p> <b>Work Type</b> : {!window.cn(applicantData) ? applicantData?.job_post?.work_type : ""}</p>
                        </div>

                        <div className="field col-12 flex flex-column">
                            <p> <b>Description</b> : {!window.cn(applicantData) && applicantData?.job_post?.job_description != undefined ? parse(applicantData?.job_post?.job_description) : ""}</p>
                        </div> */}

                        {/* {
                            !window.cn(applicantData) && applicantData?.job_post?.give_task == "Yes" ?
                                <>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Task Description</b> : {!window.cn(applicantData) && applicantData?.job_post?.task_description != undefined ? parse(applicantData?.job_post?.task_description) : ""}</p>
                                    </div>
                                </>
                                :
                                <></>
                        } */}
                    </div>
                </div>
            </div>

            {
                pageLoad && <Loader />
            }
        </>
    )
}